window.addEventListener('load', nav)

function nav(){
  let mobileNav = document.getElementById('mobile-nav')
  let burger = document.getElementById('hamburger')
  let programs = document.getElementById('programs')
  let mobilePrograms = document.getElementById('mobile-programs')
  let programsStatus = document.getElementById('programs-status')
  let programItems = document.getElementById('program-items')
  let programItemsMobile = document.getElementById('program-items-mobile')
  if(window.innerWidth >= 851){
    if(mobileNav.classList.contains('slide-in'))mobileNav.classList.remove('slide-in')
    if(!(programItemsMobile.classList.contains('hide'))){
      mobileNav.classList.add('hide')
      burger.innerHtml='<div class = "bar"></div> <div class = "bar"></div> <div class = "bar"></div>'
    }
  }
  checkNav()
  window.addEventListener('resize', checkNav)

  function checkNav(){
    if(window.innerWidth >= 851){
      programs.addEventListener('click', togglePrograms)
      if(mobileNav.classList.contains('slide-in'))mobileNav.classList.remove('slide-in')
      if(document.body.classList.contains('no-scroll')) document.body.classList.remove('no-scroll')
      if(!(programItemsMobile.classList.contains('hide'))){
        mobileNav.classList.add('hide')
      }
      document.addEventListener('click', function(event){
        if(event.target.id !== 'programs'){
          if(!(programItems.classList.contains('hide'))) programItems.classList.add('hide')
        }
      })
    }else{
      burger.addEventListener('click', toggleDrop)
      mobilePrograms.addEventListener('click', toggleProgramsMobile)
    }
  } 
  function toggleDrop(){
    let bar1 = document.getElementById('bar-1')
    let bar2 = document.getElementById('bar-2')
    let bar3 = document.getElementById('bar-3')

    if(mobileNav.classList.contains('slide-in')){
      mobileNav.classList.add('slide-out')
      mobileNav.classList.remove('slide-in')
      if(document.body.classList.contains('no-scroll')) document.body.classList.remove('no-scroll')
    }else if(mobileNav.classList.contains('slide-out')){
      mobileNav.classList.add('slide-in')
      mobileNav.classList.remove('slide-out')
      if(!(document.body.classList.contains('no-scroll'))) document.body.classList.add('no-scroll')
    }else{
      mobileNav.classList.add('slide-in')
      if(!(document.body.classList.contains('no-scroll'))) document.body.classList.add('no-scroll')
    }

    if(mobileNav.classList.contains('slide-in')){
      if(!(bar1.classList.contains('spin-right'))) {
        bar1.classList.add('spin-right')
        bar2.classList.add('spin-left')
        bar3.classList.add('hide')
      }
    }else{

      if((bar1.classList.contains('spin-right')) || (bar2.classList.contains('spin-left')) || (bar3.classList.contains('hide'))){
        bar1.classList.remove('spin-right')
        bar2.classList.remove('spin-left')
        bar3.classList.remove('hide')
      }
    }

  }

  function togglePrograms(){
    if(programItems.classList.contains('hide')){
      programItems.classList.remove('hide')
    }else if(!(programItems.classList.contains('hide'))){
      programItems.classList.add('hide')
    }
  }

  function toggleProgramsMobile(){
    if((programItemsMobile.classList.contains('hide'))){
      programsStatus.innerText='-'
    }else{
      programsStatus.innerText='+'
    }
    
    if(programItemsMobile.classList.contains('hide')){
      programItemsMobile.classList.remove('hide')
    }else if(!(programItemsMobile.classList.contains('hide'))){
      programItemsMobile.classList.add('hide')
    }
  }

}